import config from "./config-loader";

const load = () => {

    return Promise.all([
        import(
            /* webpackChunkName: "router_js" */
            "router_js"
            ),
        import(
            /* webpackChunkName: "urijs" */
            "urijs"
            ),
        import(
            /* webpackChunkName: "symbiosis-module" */
            "./module"
            ),
        import(
            /* webpackChunkName: "polyfill" */
            /* webpackMode: "eager" */
            "./polyfill"
            )
    ]);

};



export function init (options) {

    console.log('config', config);
    console.log('option', options);
    
    load().then(([{default:Router}, {default:URI}, {loadModules}]) => {

        let path                = '/',
            current_uri         = URI(window.location.href),
            site_uri            = URI(config.app.site_url),
            current_path        = current_uri.pathname(),
            site_path           = site_uri.pathname(),
            i                   = current_path.search(site_path);
        
        if ( i > -1 ) {
            path = current_path.substr(site_path.length + i);
        }
        
        let router = new Router({});

        loadModules(router, options.modules, {}).subscribe((module) => {
            // handlers[module.name] = module.handler;
            router.handleURL(path);
            if ( options.onModuleLoaded ) {
                options.onModuleLoaded();
            }
        });
        
    });
};



// $(document).ready(function () {
//    
//     ;(function ($, bootstrapAlerts) {
//       
//         let $links = $('[data-toggle="confirm"]');
//        
//         if (!$links.length) {
//             return;
//         }
//        
//         $links.on('click.confirm', function (e) {
//            
//             let $this = $(this),
//                 msg = $this.data('title');
//
//             bootstrapAlerts.confirm(msg, (function ($el) {
//                
//                 return function () {
//                    
//                     if ($el.is('a')) {
//                         window.location = $el.prop('href');
//                     }
//                    
//                 };
//                
//             }($this)));
//            
//             e.preventDefault();
//            
//             return false;
//         });
//        
//     }($, window.bootstrapAlerts));
//    
//     (function ($) {
//        
//         let $el = $('.drag-drop-attachment');
//        
//         if ( !$el.length ) {
//             return;
//         }
//        
//         $el.dragdropUpload();
//        
//     } ($));
// });